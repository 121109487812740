import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { HttpErrorResponse } from '@angular/common/http';
import ApiOperation from '../api/models/api-operation';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  private loading;

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
  ) {
  }

  async showMessage(message: string, messageHeader: string = '') {
    const c = await this.alertController.create({
      header : messageHeader,
      message,
      mode   : 'ios',
      buttons: ['OK'],
    })
    await c.present()
    await c.onDidDismiss()
  }

  async showLoadingMessage(message = 'bezig met laden') {
    this.loading = await this.loadingController.create({
      message,
    });
    return this.loading.present();
  }

  dismissLoading() {
    this.loading.dismiss();
  }

  showApiOperationAsMessage(op: ApiOperation) {
    this.showMessage(op.message, op.messageHeader);
  }

  /**
   * Shows the first error message from the data.errors.etc
   */
  showFirstErrorMessage(error: ApiOperation | HttpErrorResponse) {
    let apiOp = new ApiOperation();

    if (error instanceof HttpErrorResponse) {
      apiOp.data    = error.error;
      apiOp.message = error.error.message;
    } else {
      apiOp = error;
    }

    const keys              = Object.keys(apiOp.data.errors);
    const firstErrorMessage = apiOp.data.errors[keys[0]];
    return this.showMessage(firstErrorMessage, apiOp.message);
  }

}
