import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { config } from '@core/config/config';
import { AuthGuardService } from '@core/guards/auth.guard';
import { ApiInterceptor } from '@core/interceptors/api.interceptor';
import { NewMessageObserverService } from '@core/services/new-message-observer/new-message-observer.service';
import { PhotoDownloadProvider } from "@core/services/photo-download/photo-download";
import { PusherService } from '@core/services/pusher/pusher.service';
import { WdcApi2Service } from "@core/services/wdcapi2/wdc-api2.service";
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { PhotoLibrary } from '@ionic-native/photo-library/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AvatarUploadService} from "@core/services/avatar-upload/avatar-upload.service";
import {ImagePicker} from "@ionic-native/image-picker/ngx";
import {FileTransfer} from "@ionic-native/file-transfer/ngx";



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, IonicModule.forRoot({
      mode: 'ios',
    }),
    HttpClientModule,
    AppRoutingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    IonicStorageModule.forRoot({
      name: '__schagen',
      driverOrder: ['sqlite', 'indexeddb', 'websql']
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    ImagePicker,
    FileTransfer,
    StatusBar,
    SplashScreen,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: NG_ENTITY_SERVICE_CONFIG, useValue: {
        baseUrl: config.api.host + '/v1'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    AuthGuardService,
    NewMessageObserverService,
    PusherService,
    PhotoDownloadProvider,
    PhotoLibrary,
    WdcApi2Service,
    AvatarUploadService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
