import { environment } from '../../environments/environment';

console.log('production?', environment.production);

let baseUrl = 'https://6am-api.test';

// Override so local can access staging
baseUrl = 'https://api.wedaycare.com';

export const config = {
  api: {
    host         : baseUrl,
    client_secret: 'djVXpXBSPg2uVds7M8gYydpNtst4TVh2OCKTIV9t',
    client_id    : '2',
    shard        : 'clubadventure',
  },

  pusher: {
    key      : '6d309ddd87211bd73a1e',
    cluster  : 'eu',
    encrypted: true
  },
};
