import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import {
  Capacitor,
  Plugins,


  PushNotificationActionPerformed, PushNotificationToken
} from '@capacitor/core';
import { ApiService } from "@core/services/api/api.service";
import { Platform } from "@ionic/angular";
const { PushNotifications } = Plugins;
console.log(Plugins)

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  public token: string;
  private isApp: boolean;

  constructor(
    private platform: Platform,
    private api: ApiService,
    private route: Router,
  ) {
    let vm = this;
    vm.platform.ready().then(() => {
      vm.isApp = Capacitor.isNative

      if(vm.isApp){
        // this.handleNotifications();
      }
    });

  }

  public isEnabled(){
    return new Promise((res,rej) => {
      return true;
    })
  }

  /* FCM specific stuff */
  handleFCMRegistration(){
    // Request notification permission
    return this.registerDevice();
  }

  // No mention of refresh in Capacitor Push Notifs https://capacitorjs.com/docs/v2/apis/push-notifications
  // handleTokenRefresh() {
  //   if(this.isApp) {
  //     this.platform.ready().then(() => {
  //       this.fcm.onTokenRefresh().subscribe(token => {
  //         this.users.self().then(user => {
  //           if(user != null){
  //             this.bindToken(token)
  //           }
  //         });
  //       });
  //     });
  //   }
  // }

  registerDevice() {
    this.platform.ready().then(() => {
      if (this.isApp) {
        this.registerSyncedUser()
        this.handleNotifications()
      }
    });
  }

  registerSyncedUser() {
    let vm = this; 

    // PushNotifications.requestPermissions().then(result => {
    //     // Register with Apple / Google to receive push via APNS/FCM
    //     PushNotifications.register();
    // })
    PushNotifications.requestPermission().then(result => {
      // Register with Apple / Google to receive push via APNS/FCM
      if (result.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        console.log('⭐️registering push notifs');
        PushNotifications.register().then(
          () => {
            console.log('⭐️Push Notifications Success');
          }
        ).catch((e) => {
          console.log(e);
        });
      } else {
        console.log('🚫push notifs failed.')
      }
  })

    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        // alert('Push registration success, token!: ' + token.value);
        vm.bindToken(token.value)
      },
    );
  }

  handleNotifications(){
    PushNotifications.addListener('registrationError', (error: any) => {
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        // alert('Push received: ' + JSON.stringify(notification));
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        // alert('Push action performed: ' + JSON.stringify(notification));
        switch (notification.notification.data.type) {
          case 'chat':
            this.route.navigate(['/dashboard/contact/' + notification.notification.data.entity_id]).then(() => {
              window.location.reload()
            });
            break;
          case 'diary':
            this.route.navigate(['/dashboard/video-album/' + notification.notification.data.entity_id]).then(() => {
              window.location.reload()
            });
            break;
          case 'news':
            this.route.navigate(['/dashboard/news/' + notification.notification.data.entity_id]).then(() => {
              window.location.reload()
            });
            break;
          default:
            break;
        }
      },
    );
  }

  bindToken(token) {
    console.log('token', token);
    return this.api.post('fcm/register-token', {
      token: token
    }).subscribe();
  }
}
