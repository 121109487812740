import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AlertsService } from '../services/alerts/alerts.service';
import { SessionQuery } from '../services/session/session.query';
import { SessionService } from '../services/session/session.service';
import { config } from '@core/config/config';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private lastMessageTime = 0;
  private error: any;

  constructor(
    private router: Router,
    private nav: NavController,
    private alerts: AlertsService,
    private session: SessionQuery,
    private sessionService: SessionService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokens = this.session.getTokens();
    let newReq = request.clone();

    // Find out if the token is first party.
    if (this.isFirstParty(request.url)) {
      newReq = request.clone({
        // Set the request headers
        headers: this.headers(request, tokens)
      });
    }

    // Handle Errors
    return next.handle(newReq).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        // Are we dealing with expected errors?
        // 401 Unauthorized - Token Issue
        // 0 - CORS Issue
        switch (err.status) {
          case 429:
            this.alerts.showMessage('We zijn de API-limiet overschreden. Probeer het later opnieuw.');
            // Throw a non HttpErrorResponse to ensure it doesn't get triggered again.
            return;
          case 0:
            // Debounce so this message only appears once every 2 seconds.
            const d = new Date();
            if (this.lastMessageTime + 2000 < d.getTime()) {
              this.lastMessageTime = d.getTime();
              this.alerts.showMessage('Je lijkt offline te zijn.');
            }
            // Throw a non HttpErrorResponse to ensure it doesn't get triggered again.
            return throwError({error: err.status});
          case 403:
          case 401:
            // Try to refresh
            this.error = err;

            if (err.url.includes('/oauth/token')) {
              // Oauth token failed. Throw
              this.sessionService.logout();
              return throwError(err);
            } else {
              return this.sessionService.refresh(tokens.refresh_token)
                .pipe(
                  delay(5000),
                  concatMap((data) => {
                    newReq = request.clone({
                      headers: this.headers(request, data)
                    });
                    return next.handle(newReq);
                  })
                );
            }
          case 400:
            return throwError(err);
        }
        return throwError(err);
      }));
  }

  private isFirstParty(url: string) {
    const api = config.api;
    const validPrefixes = [
      api.host
    ];

    let contains = false;

    validPrefixes.forEach(element => {
      // Do we already have a match or do we check url again
      contains = contains ? contains : (url.indexOf(element) === 0);
    });

    return contains;
  }

  headers(request: HttpRequest<any>, tokens?) {
    const api = config.api;

    let headers = request.headers;

    if (!request.url.includes('/profile-image') &&
      !request.url.includes('/image')
    ) {
      headers = headers.append('Accept', 'application/json');
    }

    headers = headers.append('Shard', api.shard);

    if (tokens != null) {
      const bearer = 'Bearer ' + tokens.access_token;
      headers = headers.append('Authorization', bearer.trim());
    }

    // headers = headers.append('Shard', config.api.shard);

    return headers;
  }
}
