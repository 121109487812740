import { Component } from '@angular/core';
import { Router } from "@angular/router";
import {
  Plugins
} from '@capacitor/core';
import { NotificationsService } from "@core/services/notifications/notifications.service";
import { CurrentUserService } from '@core/state/current_user/current-user.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';

const { PushNotifications } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private currentUser: CurrentUserService,
    private route: Router,
    private notifications: NotificationsService,
  ) {

  }

  initializeApp() {
    this.statusBar.styleDefault();
    // this.splashScreen.hide();
    this.currentUser.get().subscribe();
    console.log('setupnotifs')
  }

  ngOnInit() {
    console.log('oninit')
    this.platform.ready().then(() => {
      console.log('platformready')
      this.initializeApp();
    })
  }

}
