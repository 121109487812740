import { Injectable } from '@angular/core';
import {ApiService} from "@core/services/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class WdcApi2Service {

  constructor(
    private api: ApiService,
  ) {
  }

  public changePassword(old : string, new_password : string, verification: string){
    let data = {
      'password' : old,
      'new_password' : new_password,
      'new_password_confirmation' : verification
    };

    return this.api.put("users/update-password", data)
  }

}
