import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PushedMessage } from '@core/models/pushed-message';
import { CurrentUserQuery } from '@core/state/current_user/current-user.query';
import { PusherService } from '@core/services/pusher/pusher.service';
import Channel from 'pusher-js/types/src/core/channels/channel';
import { CurrentUserService } from '@core/state/current_user/current-user.service';

/**
 * Use the 'observable' property to subscribe to messages.
 * Use the 'newMessagesCount' property to get an active count of new messages.
 */
@Injectable()
export class NewMessageObserverService implements OnDestroy {
  public newMessagesCount = 0;

  private loaded: Promise<null>                  = null;
  private observable: Subject<PushedMessage>     = new Subject();
  private newsObservable: Subject<PushedMessage> = new Subject();

  private channel: Channel = null;

  constructor(
    private pusher: PusherService,
    private currentUserQuery: CurrentUserQuery,
    private currentUserService: CurrentUserService,
  ) {
    const vm   = this;

    currentUserService.get().subscribe(() => {
      const user = currentUserQuery.getValue();
      vm.loaded = new Promise((resolve) => {
        vm.pusher.subscribeChannel('user.' + user.id, true).then(channel => {
          console.log('CHANEL')
          console.log(channel)
          this.channel = channel;
          this.channel.bind('chat.message.created', (data) => {
            console.log(data);
            vm.observable.next(data);
            vm.newMessagesCount++;
          });
          // resolve(null);
  
          this.channel.bind('chat.message.updated', (data) => {
            vm.observable.next(data);
          });
  
          this.channel.bind('news.message.created', (data) => {
            vm.newsObservable.next(data);
          });
        });
      });
    })
  }

  subscribe(next?: (value: PushedMessage) => void, error?: (error: any) => void, complete?: () => void) {
    return this.observable.subscribe(next, error, complete);
  }

  subscribeNews(next?: (value: PushedMessage) => void, error?: (error: any) => void, complete?: () => void) {
    return this.newsObservable.subscribe(next, error, complete);
  }

  ngOnDestroy(): void {
    this.channel.unbind_all();
    this.channel.unsubscribe();
  }
}
