import { Injectable } from '@angular/core';
import { CurrentUserStore } from './current-user.store';
import { ApiService } from '@core/services/api/api.service';
import { tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CurrentUserService {

  constructor(
    private currentUserStore: CurrentUserStore,
    private api: ApiService
  ) {
  }

  get() {
    return this.api.get('users/self').pipe(tap(data => {
      console.log(data);
      this.currentUserStore.update(data.data);
      return data;
    }));
  }

  getNotifications() {
    return this.api.get('users/notification-preferences');
  }
}
