/**
 * Used for API endpoints that can fail/
 * success - if the operation succeeds, it is true
 * error - if the operation fails, this has the error code.
 * message - message about the operation
 * data - misc. data. if it is an error, it contains ErrorData
 */
import ErrorData from './error-data';
import {HttpErrorResponse} from '@angular/common/http';

export default class ApiOperation {
  success: boolean = false;
  error?: string;
  message?: string;
  messageHeader?: string;
  data?: any | ErrorData;
  status?: string = '';

  constructor(success = false) {
    this.success = success;
    if (success) {
      this.message = 'Succes';
    }
  }

  static fromHttpError(error: HttpErrorResponse) {
    const op = new ApiOperation();
    op.data    = error['error'];
    op.message = error['error'].message;
    op.success = false;

    const keys              = Object.keys(op.data.errors);
    const firstErrorMessage = op.data.errors[keys[0]];
    op.messageHeader = firstErrorMessage;
    return op;
  }
}
