import { Injectable } from '@angular/core';
import Pusher from 'pusher-js/with-encryption';
import { config } from '@core/config/config';
import { SessionQuery } from '@core/services/session/session.query';

@Injectable()
export class PusherService {

  protected base                   = 'v1/users';
  protected primaryKey             = 'id';
  protected isReady: Promise<null> = null;

  private pusher = null;

  constructor(
    private sessionQuery: SessionQuery,
  ) {
    const vm = this;
    /**
     * Needed, otherwise, pusher will be undefined when subscribe function is called before it is ready,
     * causing an error.
     */
    this.isReady = new Promise((resolve, reject) => {
      const pusherConfig = config.pusher;
      const api          = config.api;
      const accessToken  = this.sessionQuery.getToken();

      const host = api.host + '/';

      this.pusher = new Pusher(
        pusherConfig.key, {
          cluster     : pusherConfig.cluster,
          authEndpoint: host + 'v1/broadcasting/auth',
          auth        : {
            headers: {
              Accept       : 'application/json',
              Authorization: 'Bearer ' + accessToken,
              shard        : api.shard,
            },
          },
        },
      );

      // Might help with debugging if something fails
      this.pusher.connection.bind('error', (err) => {
        console.warn(err);
        reject();
      });

      resolve();
    });
  }

  /**
   * Subscribes to a new Pusher connection.
   * Automatically adds private-{{channelname}}. prefix.
   * @param name name in [channelname].{{name}} i.e. if name=user.1: default.user.1
   * @param isPrivate Adds private- prefix, i.e. private-default.user.1
   */
  public subscribeChannel(name, isPrivate = false) {
    return this.isReady.then(() => {
      return this.handleSub(name, isPrivate);
    });
  }

  public unsubscribeChannel(name, isPrivate = false) {
    let channelName = this.getChannelName(name);

    if (isPrivate) {
      channelName = 'private-' + channelName;
    }

    return this.pusher.unsubscribe(channelName);
  }

  // Returns Pusher channel
  private handleSub(name, isPrivate) {
    let channelName = this.getChannelName(name);

    if (isPrivate) {
      channelName = 'private-' + channelName;
    }
    return this.pusher.subscribe(channelName);
  }

  private getChannelName(name) {
    const api         = config.api;
    return api.shard + '.' + name;
  }

}
