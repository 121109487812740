import { resetStores, Store, StoreConfig } from '@datorama/akita';
import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {of} from 'rxjs';
import {NavController} from '@ionic/angular';

export interface SessionState {
    token_type: string;
    expires_in: string;
    access_token: string;
    refresh_token: string;
}

export function createInitialState(): SessionState {
    return {
        token_type: '',
        expires_in: '',
        access_token: '',
        refresh_token: '',
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
    constructor(
        private storage: Storage,
        private nav: NavController,
    ) {
        super(createInitialState());
    }

    logout() {
        resetStores({ exclude: []}); // optionally exclude stores
        return of(this.storage.clear().then(() => {
            this.update(createInitialState());
            this.nav.navigateRoot('/login');
        }));
    }
}
