import { Injectable } from '@angular/core';
import { SessionQuery } from '../session/session.query';
import * as moment from 'moment';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import {WdcApi2Service} from "@core/services/wdcapi2/wdc-api2.service";
import {ApiService} from "@core/services/api/api.service";
import {config} from "@core/config/config";

@Injectable({
  providedIn: 'root'
})
export class AvatarUploadService {

  constructor(
    private session: SessionQuery,
    private imagePicker: ImagePicker,
    private transfer: FileTransfer,
    private api: ApiService,
  ) { }

  async pickImageFromMobile() {
    const options = {
      maximumImagesCount: 1,
      width: 800,
      height: 800,
      quality: 80,
      disable_popover: true
    };

    return this.imagePicker.getPictures(options);
  }

  uploadFileFromMobile(newImage, url) {
    let tokens = this.session.getTokens();

    const fileTransfer: FileTransferObject = this.transfer.create();
    console.log('BEARER', 'Bearer ' + tokens.access_token.trim());

    const api = config.api;

    let fileUploadOption: FileUploadOptions = {
      fileKey: 'image',
      fileName: `Photo_${moment().format('YYYY-MM-DD_hh.mm.ss_s')}`,
      headers: {
        Authorization: 'Bearer ' + tokens.access_token.trim(),
        Accept       : 'application/json',
        shard        : api.shard,
      }
    }

    return fileTransfer.upload(newImage, this.api.endpointify(url), fileUploadOption);
  }

  async browseFileFromBrowser() {
    return new Promise(resolve => {
      let input = document.createElement('input');
      input.type = 'file';
      input.onchange = () => {
        let files = Array.from(input.files);
        resolve(files[0]);
      };
      input.click();
    });
  }
}
