import {Query} from '@datorama/akita';
import {Injectable} from '@angular/core';
import {SessionState, SessionStore} from './session.store';

@Injectable({providedIn: 'root'})
export class SessionQuery extends Query<SessionState> {
    isLoggedIn$ = this.select(state => !!state.access_token);
    token$ = this.select('access_token');

    constructor(protected store: SessionStore) {
        super(store);
    }

    getToken() {
        return this.getValue().access_token;
    }

    getTokens() {
        return {
            access_token: this.getValue().access_token,
            refresh_token: this.getValue().refresh_token,
        }
    }

    isLoggedIn() {
        return !!this.getToken();
    }
}
