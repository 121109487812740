import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/guards/auth.guard';

const routes: Routes = [
  {
    path      : '',
    redirectTo: 'login',
    pathMatch : 'full'
  },
  {
    path        : 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path        : 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path       : 'dashboard',
    canActivate: [AuthGuardService],
    children   : [
      {
        path      : '',
        redirectTo: 'overview',
        pathMatch : 'full',
      },
      {
        path        : 'overview',
        loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewPageModule)
      },
      {
        path        : 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
      },
      {
        path        : 'child-overview',
        loadChildren: () => import('./pages/child-overview/child-overview.module').then(m => m.ChildOverviewPageModule)
      },
      {
        path        : 'contact',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
      },
      {
        path        : 'chat',
        loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
      },
      {
        path        : 'profile/:id',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule),
      },
      {
        path        : 'photo-album',
        loadChildren: () => import('./pages/photo-album/photo-album.module').then(m => m.PhotoAlbumPageModule)
      },
      {
        path        : 'news/:id',
        loadChildren: () => import('./pages/news/news.module').then(m => m.NewsPageModule)
      },
      {
        path        : 'video-album/:id',
        loadChildren: () => import('./pages/video-album/video-album.module').then(m => m.VideoAlbumPageModule)
      },
      {
        path        : 'invite-parent/:id',
        loadChildren: () => import('./pages/invite-parent/invite-parent.module').then(m => m.InviteParentPageModule)
      },
      {
        path        : 'settings-change-password',
        loadChildren: () =>
          import('./pages/settings-change-password/settings-change-password.module').then(m => m.SettingsChangePasswordPageModule)
      },
      {
        path        : 'settings-push-notification',
        loadChildren: () => import('./pages/settings-push-notification/settings-push-notification.module')
          .then(m => m.SettingsPushNotificationPageModule)
      },
      {
        path: 'documents/:id',
        loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule)
      },
    ],
  },
  {
    path: 'activate',
    loadChildren: () => import('./pages/activate/activate.module').then( m => m.ActivatePageModule)
  },
  {path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
