import { Injectable } from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CommonToastsService {

  constructor(
    public toastController: ToastController
  ) { }

  async success(message: string = 'Saved') {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      color: 'success'
    });
    toast.present();
  }

  async error(message: string = 'Fout!') {
    const toast = await this.toastController.create({
      message: message,
      color: 'danger',
      buttons: [{
          text: 'OK',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  httpError(httpexception) {
    return this.error(httpexception['error']['message']);
  }
}
