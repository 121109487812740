import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionQuery } from '../services/session/session.query';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private auth: SessionQuery,
              private router: Router
  ) {
  }

  canActivate() {
    // Find out if user is logged in.
    if (!this.auth.isLoggedIn()) {
      return this.router.parseUrl('/login');
    } else {
      return true;
    }
  }
}
