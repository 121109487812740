import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { createCurrentUser } from './current-user.model';
import { User } from '@core/models/user';

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'currentUser'})
export class CurrentUserStore extends Store<User> {

  constructor() {
    super(createCurrentUser({}));
  }

}
