import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SessionStore } from './session.store';
import { SessionQuery } from './session.query';
import { ApiService } from '../api/api.service';
import { config } from '../../config/config';
import { Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';

interface Credentials {
  grant_type: 'password';
  scope: '*';
  client_id: string;
  client_secret: string;
  username: string;
  password: string;
}

@Injectable({providedIn: 'root'})
export class SessionService {
  private _refreshing = false;

  constructor(private sessionStore: SessionStore,
              private sessionQuery: SessionQuery,
              private http: HttpClient,
              private oauth: ApiService,
  ) {
  }

  login(username, password) {
    this._refreshing = false;

    const creds: Credentials = {
      grant_type   : 'password',
      scope        : '*',
      client_id    : config.api.client_id,
      client_secret: config.api.client_secret,
      username,
      password,
    };
    return this.oauth.post('oauth/token', creds).pipe(tap(user => {
      console.log(user);
      this.sessionStore.update(user);
    }));
  }

  logout() {
    resetStores()
    return this.sessionStore.logout();
  }

  refresh(refresh_token: string) {
    return this.oauth.post('oauth/token', {
      refresh_token,
      grant_type   : 'refresh_token',
      client_id    : config.api.client_id,
      client_secret: config.api.client_secret,
      scope        : '*'
    }).pipe(
      tap((data) => {
        this.sessionStore.update(data);
      }),
    );
  }

}
