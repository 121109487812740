import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Media, MediaAlbum } from '@capacitor-community/media';
import {
    FilesystemDirectory, Plugins
} from "@capacitor/core";
import { PhotoLibrary } from "@ionic-native/photo-library/ngx";
import { AlertController, Platform } from "@ionic/angular";


const {Filesystem} = Plugins;
const media = new Media();

/*
  Provides a way to download photos via
  - browser Save As
  - Android download to Gallery
  - iOS download to Photo Roll
*/
@Injectable()
export class PhotoDownloadProvider {
    private platform

    constructor(
        public http: HttpClient,
        private platformx: Platform,
        private photoLibrary: PhotoLibrary,
        private alertController: AlertController,
    ) {
    }

    public isMassDownloading = false // If true, will not show download finished message

    init() {
        return this.platformx.ready().then(platform => {
            this.platform = platform
        })
    }

    downloadPhotoFromUrl(url, album = "ClubAdventure") {
        if (this.platform.toLowerCase() === 'cordova') {
            return this.savePicture(url);
        } else {
            /*
              On latest browsers, this will just open a new tab with the image.
              This is because image downloading only works on same origin urls.

              Tried workarounds
                - Download as blob
                  - Not going to work, as you need to do cross-origin request to access the image via
                  JS. See: https://stackoverflow.com/a/49500465/2531140 .
            */
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = url;
            link.download = "caf.jpg";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            return new Promise((resolve => {
                resolve()
            }))
        }
    }

    downloadVideoFromUrl(url, album = "ClubAdventure") {
        if (this.platform.toLowerCase() === 'cordova') {
            this.photoLibrary
                .requestAuthorization({read: true, write: true})
                .then(async () => {
                    // Workaround: In order for the plugin to save video, the links need to have proper extension.
                    let modifiedUrl = url;

                    const loading = await this.alertController.create({
                        message: "Bezig met opslaan...",
                    });
                    loading.present().then(() => {
                    });

                    // media.saveVideo({ path: modifiedUrl, album })
                    this.photoLibrary
                        .saveVideo(modifiedUrl, album)
                        .then(async () => {
                            loading.dismiss();
                            const alert = await this.alertController.create({
                                message: "Video opgeslagen",
                                buttons: ["OK"],
                            });
                            alert.present();
                        })
                        .catch(async (err) => {
                            loading.dismiss();
                            const alert = await this.alertController.create({
                                message: err.toString(),
                                buttons: ["OK"],
                            });
                            alert.present();
                        });



                });
        } else {
            /*
              On latest browsers, this will just open a new tab with the image.
              This is because image downloading only works on same origin urls.

              Tried workarounds
                - Download as blob
                  - Not going to work, as you need to do cross-origin request to access the image via
                  JS. See: https://stackoverflow.com/a/49500465/2531140 .
            */
            let link = document.createElement("a");
            link.target = "_blank";
            link.href = url;
            link.download = "daycare.mp4";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    private async readAsBase64(url) {
        return new Promise<any>((resolve, reject) => {
            // Fetch the photo, read as a blob, then convert to base64 format

            fetch(url, {cache: "no-cache"})
                .then(async (res) => {
                    res.blob().then(async (blob: Blob) => {
                        this.convertBlobToBase64(blob).then((stream) => resolve(stream));
                    });
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    convertBlobToBase64(blob: Blob) {
        return new Promise((resolve, reject) => {
            const reader = this.getFileReader();
            reader.onerror = (err) => {
                console.log(reader.error);
                reject(err);
            };
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(blob);
        });
    }

    getFileReader(): FileReader {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)[
            "__zone_symbol__originalInstance"
            ];
        return zoneOriginalInstance || fileReader;
    }

    private async savePicture(url) {
        // Convert photo to base64 format, required by Filesystem API to save
        const base64Data = await this.readAsBase64(url);

        // Write the file to the data directory
        const fileName = new Date().getTime() + ".jpeg";
        const savedFile = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: FilesystemDirectory.Data,
        });

        let albumName = "ClubAdventure";

        // TODO: Unspagetti this later
        if (this.platformx.is("ios")) {
            let albumBundle = await media.getAlbums();

            let filtered = albumBundle.albums.filter((el: MediaAlbum) => {
                return el.name === albumName;
            });

            if (filtered.length === 0) {
                await media.createAlbum({name: albumName});

                albumBundle = await media.getAlbums();

                filtered = albumBundle.albums.filter((el: MediaAlbum) => {
                    return el.name === albumName;
                });
            }
            albumName = filtered.pop().identifier;
        }

        media
            .savePhoto({path: savedFile.uri, album: albumName})
            .then(async () => {
                if (!this.isMassDownloading) {
                    const alert = await this.alertController.create({
                        message: "Afbeelding opgeslagen",
                        buttons: ["OK"],
                    });
                    alert.present();
                }
            })
            .catch(async (err) => {
                const alert = await this.alertController.create({
                    message: err.toString(),
                    buttons: ["OK"],
                });
                alert.present();

                // Use webPath to display the new image instead of base64 since it's
                // already loaded into memory
                // return {
                //   filepath: fileName,
                //   webviewPath: cameraPhoto.webPath
                // };
            });
    }
}
