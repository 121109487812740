export default class ApiEndpoint {
  url     = '';
  sort    = '-created_at';
  filters = {};
  limit   = 10;

  constructor(url) {
    this.url = url;
  }

  build() {
    let opts        = [];
    let queryString = '';

    opts.push('sort=' + this.sort);

    for (let i in this.filters) {
      if (this.filters[i]) {
        const field = i;
        const value = this.filters[i];
        opts.push('filter[' + field + ']=' + value); // URL Encode
      }
    }


    if (opts.length > 0) {
      queryString = '?' + opts.join('&');
    }

    return this.url + queryString;
  }
}
