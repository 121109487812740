import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CurrentUserStore } from './current-user.store';
import { User } from '@core/models/user';

@Injectable({providedIn: 'root'})
export class CurrentUserQuery extends Query<User> {

  constructor(protected store: CurrentUserStore) {
    super(store);
  }

}
