import {Injectable} from '@angular/core';
import {fromPromise} from 'rxjs/internal-compatibility';
import {catchError, retry} from 'rxjs/operators';
import {NavController} from '@ionic/angular';
import ApiEndpoint from './api-endpoint';
import {Observable, throwError} from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { CommonToastsService } from '@core/services/common-toasts/common-toasts.service';
import { SessionStore } from '../session/session.store';
import { AlertsService } from '@core/services/alerts/alerts.service';
import { SessionQuery } from '@core/services/session/session.query';
import { config } from '@core/config/config';

/**
 * This service automatically adds {baseurl}/v1/ to the url so you don't have to.
 * Handles errors as well.
 */
@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private http: HttpClient,
                private nav: NavController,
                private alerts: AlertsService,
                private sessionStore: SessionStore,
                private sessionQuery: SessionQuery,
                private toasts: CommonToastsService
    ) {
    }

    headers() {
        const accessToken = this.sessionQuery.getToken();
        const headers = new HttpHeaders();
            // .set('Content-Type', 'application/json')
            // .set('Accept', 'application/json');

        if (accessToken) {
            // headers = headers.set('Authorization', 'Bearer ' + accessToken.trim());
        }

        return {
            headers
        };
    }

    get(url: string | ApiEndpoint) {
        return this.callCommand(
            this.http.get(this.endpointify(url), this.headers())
        );
    }

    post(url: string | ApiEndpoint, data) {
        return this.callCommand(
            this.http.post(this.endpointify(url), data, this.headers())
        );
    }

    put(url: string | ApiEndpoint, data) {
        return this.callCommand(
            this.http.put(this.endpointify(url), data, this.headers())
        );
    }

    delete(url) {
        return this.callCommand(
            this.http.delete(this.endpointify(url), this.headers())
        );
    }

    /**
     * Standard way of calling OAuthed calls
     * Handles basic error handling.
     */
    callCommand(command: Observable<any>) {
        return command.pipe(
            catchError(err => {
                this.handleErrors(err);
                throw(err);
            })
        );
    }

    /**
     * Standard way of formatting endpoints.
     * Handles building ApiEndpoint object to proper URLs.
     */
    endpointify(url) {
        let newUrl = url;
        if (url instanceof ApiEndpoint) {
            newUrl = url.build();
        }
        return config.api.host + '/v1/' + newUrl;
    }

    /**
     * Gets all from a pagination endpoint. For real. None of that pagination stuff.
     * Returns an array of values and not a Paginator, so keep that in mind when replacing a 'get' call and handling
     * 'data.data'.
     *
     * Really messy because it is basically just a modified version of the code used on Vinea.
     * Modified so it outputs an Observable at least.
     */
    public getAll(url: string, page: number = 1, rc = [], extra = '') {
        const vm = this;

        return fromPromise(new Promise((resolve) => {
            return vm.get(url + '?page=' + page + '&limit=100' + extra).subscribe(data => {
                rc = rc.concat(data.data);

                if (data.meta.last_page > data.meta.current_page) {
                    this.getAll(url, page + 1, rc, extra).subscribe(res => {
                        console.log('res');
                        resolve(res);
                    });
                } else {
                    console.log('rc');
                    return resolve(rc);
                }
            });
        }));
    }

    handleErrors(err: HttpErrorResponse) {
        console.log(err);
        // Are we dealing with expected errors?
        // 401 Unauthorized - Token Issue
        // 0 - CORS Issue
        switch (err.status) {
            case 401:
                const selfUrl = 'people/self';
                // If api url == /self, just logout and say Unauthorized.
                if (err.url.includes(selfUrl)) {
                    this.sessionStore.logout();
                    return;
                }

                // Unauthorized.
                // Log out the user and go to login page.
                this.get(selfUrl).subscribe(data => {
                  if (data.data.id != null) {
                    this.toasts.error('Please try again later.');
                  }
                  else {
                    this.sessionStore.logout();
                  }
                });
                return;
            case 429:
                this.alerts.showMessage('We have crossed the API rate limit. Please try again later.');
                return;
            case 0:
                console.log(err);
                this.alerts.showMessage('You seem to be offline or there is a CORS issue.');
                // this.state.clear().subscribe(() => {
                //   // The user should be logged out
                //   console.log('The token seems to be unauthorized, so logout the user.');
                //   this.nav.navigateRoot('/login');
                // });
                return;
            case 500:
                this.toasts.error('API Error - ' + err.error.message);
        }
        return throwError(err);
    }
}
